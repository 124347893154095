body {
    display: flex;
    height: 100%;
    position: relative;
}

#toc-wrapper {
    height: 100%;
    overflow-y: auto;
    transition: width 0.3s;
    position: fixed;
    background-color: white;
    z-index: 1;
}


#non-toc-wrapper {
    z-index: 0;
    float: right;
    position: relative;
}

/* -- Slideout Sidebar -- */
nav.toc-fixed {
    width: 250px;
    top: 60px;
    z-index: 1;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 20px;
    background-color: transparent;
    transition: all 300ms ease-in-out;
}

.toc-fixed ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.toc-fixed ul li {
    cursor: pointer;
    padding: 6px 0;
}

#toc-toggle {
    display: none;
    z-index: 1;
}

.toc-icon {
    padding-left: 20px;
    display: block;
    top: 60px;
    left: 30px;
    z-index: 1;
    transition: all 300ms ease-in-out;
}

#body-content {
    overflow-y: auto;
}

.collapsed {
    display: none;
}
